import { Page } from "components/Layout";
import { Card } from "rebass";

export default function About() {
  return (
    <>
      <Page>
        <h1>About</h1>

        <h2>technology</h2>

        <Card paddingTop={20}>
          <h3>TradingView</h3>
          Zooker uses TradingView technology to display trading data on charts.
          TradingView is a charting platform for traders and investors, loved
          and visited by millions of users worldwide. It offers state-of-the-art
          charting tools and a space where people driven by markets can track
          important upcoming events in the{" "}
          <b>
            <a
              href="https://www.tradingview.com/economic-calendar/"
              target="_blank"
              rel="noreferrer"
            >
              Economic calendar
            </a>
          </b>
          , chat, chart, and prepare for trades.
        </Card>

        <Card paddingTop={20} marginBottom={50}>
          <h3>database</h3>

          <p>Zooker uses ClickHouse and MongoDB to store data.</p>
          <p>
            ClickHouse is a high-performance, column-oriented SQL database
            management system (DBMS) for online analytical processing (OLAP). It
            is available as both an open-source software and a cloud offering.
          </p>
          <p>
            MongoDB is a general-purpose document database. With the Community
            Edition you can self-manage and host it locally or in the cloud.{" "}
          </p>
        </Card>
      </Page>
    </>
  );
}
